<template>

  <div v-if="pageNotFound">
    <NotFoundPage></NotFoundPage>
  </div>
  <div v-else style="padding-top: 100px;">
    <div class="ps-category">
      <div class="container">
        <breadcrumb :dataList="breadcrumb"/>

        <h1 class="ps-category__name">{{ pageTitle }}<sup v-if="totalProductSize>0">({{ totalProductSize }})</sup>
        </h1>

        <div class="ps-category__content">
          <div class="row row-reverse">
            <div class="col-12 col-md-9">
              <shop-wrapper
                  :type="type"
                  :pageSize="meta.pageSize"
                  :sort="sortBy"
                  @handleChangeType="handleChangeType"
                  @handleChangeSort="handleChangeSort"
                  @handleChangePageSize="handleChangePageSize"
              />
              <div v-if="type === 'list'" class="ps-category--list">
                <template v-if="loading">
                  <div class="ps-product ps-product--list" v-for="item in 3" :key="item">
                    <!--<div class="ps-product__content">
                        <div class="ps-product__thumbnail">
                            <v-skeleton-loader type="image" ></v-skeleton-loader>
                        </div>
                        <div class="ps-product__info">
                            <v-skeleton-loader type="article" ></v-skeleton-loader>
                        </div>
                    </div>
                    <div class="ps-product__footer">
                        <v-skeleton-loader type="article" ></v-skeleton-loader>
                    </div>-->
                  </div>
                </template>
                <template v-else>
                  <product-listing v-for="item in productList" :key="item.id" :product="item"/>
                </template>
              </div>
              <div v-else-if="type === 'grid2'" class="ps-category--grid ps-category--detail">
                <div class="row" v-if="loading">
                  <div class="col-6 col-md-4" v-for="item in 3" :key="item">
                    <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                  </div>
                </div>
                <div class="row m-0" v-else>
                  <div
                      class="col-6 col-md-4 p-0"
                      v-for="item in productList"
                      :key="item.id"
                  >
                    <product-standard :product="item"/>
                  </div>
                </div>
              </div>
              <div class="ps-category--grid" v-else-if="type === 'grid3'">
                <div class="row" v-if="loading">
                  <div class="col-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                    <!--<v-skeleton-loader type="image, article" ></v-skeleton-loader>-->
                  </div>
                </div>
                <div class="row m-0" v-else>
                  <div
                      class="col-6 col-md-4 col-lg-3 p-0"
                      v-for="item in productList"
                      :key="item.id"
                  >
                    <product-standard :product="item"/>
                  </div>
                </div>
              </div>
              <div v-else class="ps-category--separate">
                <div class="row" v-if="loading">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in 4" :key="item">
                    <!--<v-skeleton-loader  type="image, article" ></v-skeleton-loader>-->
                  </div>
                </div>
                <div class="row m-0" v-else>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0" v-for="item in productList"
                       :key="item.id">
                    <product-standard :product="item"/>
                  </div>
                </div>
              </div>
              <pagination v-if="pageLength>0"
                          :page="meta.pageIndex"
                          :length="pageLength"
                          @handleChangePage="handleChangePage"
              />
              <!--<delivery />-->
            </div>
            <div class="col-12 col-md-3">

              <div class="ps-widget ps-widget--product">
                <div class="ps-widget__block">
                  <h4 class="ps-widget__title">
                    {{$t('products.categories')}}
                  </h4>

                  <div class="ps-widget__content ps-widget__category">
                    <ul class="menu--mobile">
                      <li v-for="item in categories"
                          :class="[item.extraClass, {'active': activeCategory === item.id}]"
                          :key="item.id"
                      >
                        <router-link :to="`/category/${item.slug}`">{{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>


                  <div class="card mt-4">
                    <div class="card-body">

                      <router-link
                          v-for="item in tags"
                          class='btn mr-3 mb-3 rounded-pill px-4'
                          :class=" activeTag === item.id?'btn-dark':'btn-outline-dark'"
                          :key="item.id"
                          :to="`/tag/${item.slug}`">
                        <span class=""> {{ item.name }}</span>

                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="col-12" v-if="pageDescription">
              <div v-html="nl2br(pageDescription)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumb from "../layout/page/Breadcrumb";
import ProductStandard from "../product/ProductStandard";
import ShopWrapper from "../layout/ShopWrapper";
import ProductListing from "../product/ProductListing";
import Pagination from "../layout/Pagination";
import {mapState} from "vuex";
import {getCategories, getTags} from "../../repositories/GeneralDataRepository";
import {getAllProducts, getCategory, getTag} from "../../repositories/ProductRepository";
import NotFoundPage from "./NotFoundPage";

export default {
  name: "Products",
  components: {NotFoundPage, ProductStandard, ShopWrapper, Breadcrumb, ProductListing, Pagination},
  props: {
    pageType: {
      type: String,
      default: () => {
      }
    },
    categorySlug: {
      type: String,
      default: () => {
      }
    },
  },
  data() {
    return {
      type: 'grid2',
      products: [],
      totalProductSize: 0,
      meta: {
        pageSize: 12,
        pageIndex: 1
      },
      sortBy: 'recommended',
      categories: [],
      tags: [],
      activeCategory: 0,
      activeTag: 0,
      pageTitle: "",
      pageNotFound: false,
      pageDescription: "",
    };
  },
  watch: {
    '$route'() {
      this.getProductList();
      console.log(this.$route);
    },
    '$i18n.locale'() {
      this.getList();
    }
  },
  computed: {
    ...mapState({
      languageCode: state => state.app.languageCode,
      loading: state => state.app.loading,
      favorites: state => state.wishlist.items
    }),
    slug() {
      return this.$route.params.slug;
    },
    pageLength() {
      return (this.totalProductSize > 0 ? Math.ceil(this.totalProductSize / this.meta.pageSize) : 0);
    },
    productList() {
      let result = [];
      this.products.forEach((p) => {
        p.isFavorite = this.favorites.indexOf(p.id + "") > -1;
        result.push(p)
      });
      return result;
    },
    breadcrumb() {
      let list = [
        {
          url: '/',
          text:  this.$t('menu.home')
        },
        {
          url: '/products',
          text:  this.$t('menu.products')
        }
      ];

      if (this.pageType === 'category') {
        list.push(
            {
              url: '/category/' + this.slug,
              extraClass: 'active',
              text: this.pageTitle
            }
        )
      } if (this.pageType === 'tag') {
        list.push(
            {
              url: '/tag/' + this.slug,
              extraClass: 'active',
              text: this.pageTitle
            }
        )
      }
      return list;
    },
  },
  async mounted() {
    this.$store.commit('app/setLoading', true);
   this.getList();
  },
  methods: {
    nl2br(str, is_xhtml) {
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },

    getList() {
      this.getProductList();
       getCategories(this.$i18n.locale).then((data) => {
        this.categories = data;
      })
       getTags(this.$i18n.locale).then((data) => {
        this.tags = data;
      })
    },

    async getProductList() {
      //const start = this.meta.pageSize * (this.meta.pageIndex - 1);
      // const params = {
      //     _limit: this.meta.pageSize,
      //     _start: start
      // }
      //this.products = await getProducts(params);

      switch (this.pageType) {
        case "category":
          await getCategory(this.languageCode, this.slug).then(cat => {
            if (cat.length < 1) {
              this.pageNotFound = true;
            } else {
              this.pageNotFound = false;
              this.activeCategory = cat.id;
              this.pageTitle = cat.name;
              this.pageDescription = cat.description;
              return cat;
            }
          }).then(cat => {
            console.log(cat);
            getAllProducts(this.languageCode, "", cat.id, 0, this.meta.pageIndex, this.meta.pageSize, this.sortBy).then(data => {
              this.products = data.products;
              this.totalProductSize = data.total
              this.pageNotFound = false;
            });
          })

          break;
        case "tag":
          await getTag(this.languageCode, this.slug).then(tag => {
            if (tag.length < 1) {
              this.pageNotFound = true;
            } else {
              this.pageNotFound = false;
              this.activeTag = tag.id;
              this.pageTitle = tag.name;
              this.pageDescription = tag.description;
              return tag;
            }
          }).then(tag => {
            getAllProducts(this.languageCode, "", 0, tag.id, this.meta.pageIndex, this.meta.pageSize, this.sortBy).then(data => {
              this.products = data.products;
              this.totalProductSize = data.total
              this.pageNotFound = false;
            });
          })

          break;
        default:
          this.activeCategory = 0;
          this.pageTitle =  this.$t('menu.products');
          await getAllProducts(this.languageCode, "", 0, 0, this.meta.pageIndex, this.meta.pageSize, this.sortBy).then(data => {
                this.products = data.products;
                this.totalProductSize = data.total
              }
          );
      }


      this.$store.commit('app/setLoading', false);
    },
    handleChangePage(val) {
      this.meta.pageIndex = val;
      this.getProductList();
      window.scrollTo({
        top: 0, left: 0,
        behavior: 'smooth'
      });
    },
    handleChangeType(val) {
      this.type = val;
    },
    handleChangeSort(val) {
      this.sortBy = val;
      this.meta.pageIndex = 1;
      this.getProductList();
    },
    handleChangePageSize(val) {
      this.meta.pageSize = val;
      this.meta.pageIndex = 1;
      this.getProductList();
    },
    handleFilter(type, value) {
      // list type: price, color, branch, rating
      console.log(type, value);
    }
  }
}
</script>

<style scoped>

</style>
