<template>
  <div class="container mt-5">

    <div class="ps-section__content  px-4">

      <a
          :href="'/tag/'+t.slug"
          class="btn btn-outline-danger rounded-pill mr-3 mb-3 px-5"
          style="font-size: 1.5rem"
          v-for="t in dataList" :key="t.id">
        {{ t.name }}
      </a>


    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {getTags} from "@/repositories/GeneralDataRepository";
import {imagePath} from "@/repositories/Repository";

export default {
  props: {},
  data() {
    return {
      dataList: [],

      imagePath: imagePath,
    }
  },
  watch: {
    '$i18n.locale'() {
      this.loadList();
    }
  },
  computed: {
    ...mapState({
      loading: state => state.app.loading,
      favorites: state => state.wishlist.items,
      token: state => state.auth.token,
    }),
  },

  methods: {
    loadList() {
      getTags(
          this.$i18n.locale,
      ).then(data => {
        this.dataList = data;
      })
    }
  },

  async mounted() {
    this.loadList();
  }
};
</script>
