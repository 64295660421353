<template>
  <section class="ps-section--featured">
    <div class="container">
      <h3 class="ps-section__title">{{ title }}</h3>
      <div class="ps-section__content">
        <div class="row" v-if="loading">
          <div
              class="col-6 col-sm-4 col-lg-2dot4"
              v-for="item in 5"
              :key="item"
          >
             {{ $t('general.loading') }}
          </div>
        </div>
        <div class="row m-0" v-else>
          <div
              class="col-6 col-sm-4 col-lg-2dot4 p-0"
              v-for="(category, index) in categories"
              :key="index"
          >

            <div class="ps-product ps-product--standard" v-if="category">
              <div class="ps-product__thumbnail">
                <router-link class="ps-product__image" :to="`/category/${category.slug}`" v-if="category.icon">
                  <figure>
                    <img :src="imagePath + category.icon" :alt="category.name"/>
                    <img v-if="category.banner" :src="imagePath + category.banner"
                         :alt="category.name"/>
                  </figure>
                </router-link>

              </div>
              <div class="ps-product__content">
                <h5 class="ps-product__title text-center mb-0">
                  <router-link :to="`/category/${category.slug}`">
                    <strong>{{ category.name }}</strong>
                  </router-link>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-4" v-if="buttonVisible">
          <router-link class="ps-btn ps-btn--primary" to="/products">
            {{ $t('general.allProducts') }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import {getCategories} from "@/repositories/GeneralDataRepository";
import {imagePath} from "@/repositories/Repository";

export default {
  props: {
    title: {
      type: String,
      default: () => {
      }
    },
    count: {
      type: Number,
      default: () => {
        return 10;
      }
    },
    buttonVisible: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      dataList: [],

      imagePath: imagePath,
    }
  },
  watch: {
    '$i18n.locale'() {
      this.getCategories();
    }
  },
  computed: {
    ...mapState({
      loading: state => state.app.loading,
      favorites: state => state.wishlist.items,
      token: state => state.auth.token,
    }),
    categories() {
      if (this.count < this.dataList.length) {
        return this.dataList.slice(0, this.count);
      }
      return this.dataList;
    }
  },
  methods: {
    getCategories() {
      getCategories(
          this.$i18n.locale,
      ).then(data => {
        this.dataList = data;
      })
    }
  },
  async mounted() {
    this.getCategories();
  }
};
</script>
