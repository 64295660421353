<template>
    <div style="padding-top: 100px;">
        <div class="ps-about" v-if="!isLoading">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
            </div>
            <div class="ps-about__content">
                <div class="container">
                    <h1 class="ps-blog__title">{{page.title}}</h1>
                </div>
                <section class="ps-about__project">
                    <div class="container">

                        <info-block v-for="(item, index) in dataList" :key="index" :item="item"/>

                    </div>
                </section>


            </div>
        </div>
        <div v-else>
            <div class="container">Loading...</div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import InfoBlock from "../layout/page/InfoBlock";
    import {getPage} from "../../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "InfoPage",
        components: {InfoBlock, Breadcrumb},
        data() {

            return {
                isLoading: true,
                page: {
                    title: ""
                },
                breadcrumb: [],
                dataList: []
            };

        },
        computed: {
        },
        watch: {
            '$route'() {
                this.loadData();
            },
          '$i18n.locale'() {
            this.loadData();
          }
        },
        methods: {
            async loadData() {

                this.isLoading = true;
                this.$store.commit('app/setLoading', true);
                await getPage(this.$i18n.locale, this.$route.params.slug).then(data => {
                    if (data.length < 1) {
                        this.$router.push("/");
                    } else {
                        this.page = data.page;
                        this.dataList = data.blocks;
                        this.breadcrumb = [
                            {
                                url: '/',
                                text: 'Home'
                            },
                            {
                                url: '/page/' + data.page.slug,
                                extraClass: 'active',
                                text: data.page.title
                            }
                        ];
                        this.isLoading = false;
                    }
                })

                this.$store.commit('app/setLoading', false);

            }
        },

        async mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>
