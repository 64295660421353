<template>
    <div style="margin: 100px 0;">

        <div class="ps-page--notfound">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
                <div class="ps-page__content">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-5">
                            <img
                                    src="/img/404.png"
                                    alt="not found"
                            />
                        </div>
                        <div class="col-12 col-md-6 col-lg-7">
                            <h1 class="ps-page__name">404</h1>
                            <h5>
                                {{ $t('general.notFound.title') }}
                            </h5>
                            <p>
                                {{ $t('general.notFound.text') }}
                            </p>
                            <div>
                                <router-link class="ps-btn ps-btn--primary" to="/products">
                                    {{ $t('general.notFound.products') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <featured-product title="" tag="top"/>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {getOrders} from "../../repositories/UserRepository";
    import Breadcrumb from "../layout/page/Breadcrumb";
    import FeaturedProduct from "../layout/FeaturedProduct";

    export default {
        layout: 'default',
        components: {Breadcrumb,FeaturedProduct},
        data() {
            return {
                baseDomain: baseUrl,
                orders: [],
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
            }), breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '#',
                        text: 'Xəta 404!'
                    },
                ];
            },

        },
        async created() {

            getOrders(this.token).then((orders) => {
                this.orders = orders;
            })

            this.$store.commit('app/setLoading', false);
        },
        methods: {}
    }
</script>
