<template>
  <the-header/>
  <the-header-mobile/>
  <div class="ps-home ps-home--12">
    <div class="ps-home__content">
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
  <the-footer/>

  <navigation-bottom/>
  <mobile-drawer/>
  <scroll-top/>
  <language-dialog/>
  <quick-view/>
  <add-product/>
  <rate-product/>
  <notify-dialog/>
  <loading/>


</template>

<script>

import TheHeader from "./components/layout/TheHeader";
import TheFooter from "./components/layout/TheFooter";
import TheHeaderMobile from "./components/layout/TheHeaderMobile";
import MobileDrawer from "./components/layout/MobileDrawer";
import NavigationBottom from "./components/layout/NavigationBottom";
import ScrollTop from "./components/layout/ScrollTop";
import LanguageDialog from "./components/layout/LanguageDialog";
import QuickView from "./components/product/QuickView";
import AddProduct from "./components/layout/AddProduct";
import NotifyDialog from "./components/layout/NotifyDialog";
import Loading from "./components/layout/Loading";
import RateProduct from "./components/product/RateProduct";

import cookie from 'js-cookie';

export default {
  layout: 'home-default',
  components: {
    TheHeader, TheHeaderMobile, TheFooter, NavigationBottom,
    MobileDrawer, ScrollTop, LanguageDialog, QuickView, AddProduct, NotifyDialog, Loading,
    RateProduct
  },
  data() {
    return {
      banners: []
    }
  },
  created() {
    this.$store.dispatch('app/loadSettings');

    let languageCode = cookie.get('languageCode');
    if (!languageCode) {
      languageCode = 'az';
    }
    this.$store.dispatch('app/setLanguage', {code: languageCode, name: ''});
    this.$store.dispatch('app/loadLanguages');

    this.$i18n.locale = languageCode;


    let token = cookie.get('token');

    if (token === "" || token === "null") {
      cookie.set("token", '');
      token = null;
      this.$store.dispatch('auth/setToken', token);
    }

    if (!token) {
      let hash = cookie.get('hash');
      if (!hash) {
        this.$store.dispatch('auth/generateTempToken');
      } else {
        this.$store.dispatch('auth/setHash', hash);
      }
    } else {
      this.$store.dispatch('auth/setToken', token);
      this.$store.dispatch('auth/loadUser', token);
      this.$store.dispatch('wishlist/loadFavorites', token);
    }

    this.$store.dispatch('cart/loadCart');


  },
  async mounted() {
    this.$store.dispatch('app/setLoading', true);
    // this.$store.dispatch('app/setLoading', false);
  }
}
</script>

<style lang="scss">
@import 'src/assets/style/home.scss';

@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap');

.literata-bold {
  font-family: "Literata", serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.literata-regular {
  font-family: "Literata", serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

</style>
