<template>
    <modal-slot :isVisible="dialogAddCart" title="Səbət" modalSize="lg" @modalClose="handleCloseDialog(false)">

        <div class="ps-dialog__body">
            <div class="ps-addcart__body" v-if="productOverview">
                <p class="ps-addcart__noti alert alert-success">
                    <i class="fa fa-check"> </i> {{$t('cart.productAdded')}}
                </p>
                <div class="row">
                    <div class="col-12 col-md-7 order-1 order-md-2">
                        <div class="ps-addcart__content">
                            <p> {{$t('cart.productsInCart', {productQuantity})}}</p>
                            <p class="ps-addcart__total">
                              <span class="ps-price">{{$t('cart.totalPrice', {totalPrice})}}</span>
                            </p>
                            <a
                                    class="ps-btn ps-btn--border mb-3"
                                    href="#"
                                    @click.prevent="handleCloseDialog(false)">
                                 {{$t('cart.continueShopping')}}
                            </a>


                            <router-link class="ps-btn ps-btn--border" to="/cart" @click="handleCloseDialog(false)">
                                {{$t('cart.goToCart')}}
                            </router-link
                            >
                        </div>
                    </div>

                  <div class="col-12 col-md-5 order-2 order-md-1 ">
                    <product-standard :product="productOverview"/>
                  </div>
                </div>
            </div>
        </div>
    </modal-slot>
</template>

<script>
    import {mapState} from 'vuex';
    import ProductStandard from "../product/ProductStandard";
    import ModalSlot from "./ModalSlot";

    export default {
        components: {ModalSlot, ProductStandard},
        computed: {
            ...mapState({
                productOverview: state => state.cart.productOverview,
                dialogAddCart: state => state.cart.dialogAddCart,
                cartItems: state => state.cart.cartItems
            }),
            dialog: {
                set(value) {
                    this.closeModel(value);
                    return value;
                },
                get() {
                    return this.dialogAddCart;
                }
            },
            productPrice() {
                if (this.productOverview) {
                    return this.productOverview.sale_price
                        ? this.productOverview.sale_price
                        : this.productOverview.price;
                } else {
                    return 0;
                }
            },
            productQuantity() {
                const item = this.cartItems.find(item => item.product.id === this.productOverview.id);
                if (item) {
                    return item.count;
                }
                return 1;
            },
            totalPrice() {
                return Math.round(this.productQuantity * this.productPrice * 100) / 100;
            }
        },
        methods: {
            handleCloseDialog(value) {
                this.$store.commit('cart/setDialogAddCart', value);
            }
        }
    };
</script>
