<template>

    <section v-if="item.design==='vertical'" class="ps-section--block-grid">
        <div class="ps-section__content" style="flex: 1">
            <h3 class="ps-section__subtitle">{{ item.title }}</h3>

          <img :src="baseUrl + item.photo" alt="" class="w-100 my-3" v-if="item.photo">
            <div class="ps-section__desc" v-html="content"></div>
        </div>
    </section>
    <section v-else-if="item.design==='faq'" class="ps-section--block-grid">
        <div class="ps-section__content" style="flex: 1">
            <h3 class="ps-section__subtitle">{{ item.title }}</h3>
            <div class="ps-section__desc" v-html="content"></div>
        </div>
    </section>
    <section v-else-if="item.design==='photo_left'" :class="['ps-section--block-grid']">
        <div class="ps-section__thumbnail">
            <a class="ps-section__image">
                <img :src="baseUrl + item.photo" alt="">
            </a>
        </div>
        <div class="ps-section__content">
            <h3 class="ps-section__subtitle">{{ item.title }}</h3>
            <div class="ps-section__desc" v-html="content"></div>
        </div>
    </section>
    <section v-else-if="item.design==='photo_right'" :class="['ps-section--block-grid', 'row-reverse']">
        <div class="ps-section__thumbnail">
            <a class="ps-section__image">
                <img :src="baseUrl + item.photo" alt="">
            </a>
        </div>
        <div class="ps-section__content">
            <h3 class="ps-section__subtitle">{{ item.title }}</h3>
            <div class="ps-section__desc" v-html="content"></div>
        </div>
    </section>
</template>

<script>

    import {baseUrl} from "@/repositories/Repository";

    export default {
        props: ["item"],
        data() {
            return {
                baseUrl
            }
        },
        computed: {
            content(){
               return this.nl2br(this.item.content, false);
            }
        }, methods: {
            nl2br(str, is_xhtml) {
                var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            },
        }
    }
</script>
