<template>
    <div>

        <div class="ps-shopping">
            <h3 class="ps-shopping__title">
                {{ $t('profile.addresses.title') }}
            </h3>

            <div class="alert alert-info" v-if="addresses.length<1">
              {{ $t('profile.addresses.noAddresses') }}
            </div>

            <div class=" row my-4">

                <div class="col-md-8">
                    <div class="row">

                        <div class="col-md-6 mb-5" v-for="a in addresses" :key="a.id">
                            <div class="card">
                                <div class="card-body">
                                  <strong>{{a.district.city.name}}, {{a.district.name}}</strong> <br>
                                    {{a.address}} <span v-if="a.zip">, {{a.zip}}</span>

                                    <br>
                                    <button class="btn btn-secondary mt-3" @click.prevent="deleteAddress(a.id)">
                                      {{ $t('profile.addresses.delete.action') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <strong>{{ $t('profile.addresses.add') }}</strong>
                        </div>
                        <div class="card-body">
                            <add-addess-form @addressCreated="loadAddressList"/>

                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {baseUrl} from '../../repositories/Repository';
    import {deleteAddress, getAddressList} from "../../repositories/UserRepository";
    import AddAddessForm from "../layout/AddAddessForm";

    export default {
        layout: 'default',
        components: {AddAddessForm},
        data() {
            return {
                baseDomain: baseUrl,
                addresses: [],
            }
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
        },
        async created() {

            this.loadAddressList();


            this.$store.commit('app/setLoading', false);
        },
        methods: {
            loadAddressList() {
                getAddressList(this.token).then((addresses) => {
                    this.addresses = addresses;
                })
            },
            deleteAddress(id) {

                if (confirm(this.$t('profile.addresses.delete.confirm'))) {

                    deleteAddress(this.token, id).then(() => {
                        this.loadAddressList();
                    });

                }

            }
        }
    }
</script>
