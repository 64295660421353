import Repository, {baseUrl} from './Repository';
import {formatProduct} from "./ProductRepository";


export async function getMe(token) {
    const endPoint = `me?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function generateTempToken() {
    const endPoint = `tempLogin`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.hash;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function login(email, password, hash) {
    const endPoint = `login?email=${email}&password=${password}&hash=${hash}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function forget(email) {
    const endPoint = `forget?email=${email}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function reset(email, token) {
    const endPoint = `reset?email=${email}&token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function setPassword(password, token) {
    const endPoint = `setPassword?password=${password}&token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function register(name, surname, birthday, gender, phone, email, password,captcha, hash) {
    const endPoint = `register?name=${name}&surname=${surname}&birthday=${birthday}&gender=${gender}&phone=${phone}&email=${email}&password=${password}&captcha=${captcha}&hash=${hash}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getChart(token, hash) {
    const endPoint = `chart?token=${token || ''}&hash=${hash}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                response.data.data.forEach(function (ci) {
                    ci.product = formatProduct(ci.product, []);
                });
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function addToChart(token, hash, productId, optionId, count, action) {
    const endPoint = `chart?token=${token || ''}&hash=${hash}&product=${productId}&option=${optionId}&count=${count}&action=${action}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}


export async function removeFromChart(token, hash, productId) {
    const endPoint = `chart/remove?token=${token || ''}&hash=${hash}&product=${productId}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function clearChart(token, hash) {
    const endPoint = `chart/clear?token=${token || ''}&hash=${hash}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}


export async function getFavorites(token) {
    const endPoint = `favorite?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                response.data.favorites.forEach(function (ci) {
                    ci.product = formatProduct(ci.product, []);
                });
                return response.data.favorites;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getViewHistory(token, hash, size) {
    let endPoint = `viewHistory?size=${size}`;
    if (token) {
        endPoint += `&token=${token}`;
    }
    if (hash) {
        endPoint += `&hash=${hash}`;
    }

    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                response.data.data.forEach(function (ci) {
                    ci.product = formatProduct(ci.product, []);
                });
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getFavoriteIds(token) {
    const endPoint = `favorite/ids?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.favorites;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function rateProduct(token, productId, rate, review) {
    const endPoint = `product/rate?token=${token}&product=${productId}&rate=${rate}&review=${review}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function addToFavorites(token, productId) {
    const endPoint = `favorite?token=${token}&product=${productId}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function removeFromFavorites(token, productId) {
    const endPoint = `favorite/delete?token=${token}&product=${productId}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}


export async function createOrder(token, notes) {
    const endPoint = `order?token=${token}&notes=${notes}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1000, message: 'Can not create order'};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Please check internet connection and try again '};
        });
}

export async function createOfflineOrder(hash, name, phone, address, payment, notes) {
    const endPoint = `orderOffline?hash=${hash}&notes=${notes}&name=${name}&phone=${phone}&payment=${payment}&address=${address}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1000, message: 'Can not create order'};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1000, message: 'Please check internet connection and try again '};
        });
}


export async function getOrder(token, id) {
    const endPoint = `order/${id}?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {

                let items = [];

                response.data.items.forEach(function (i) {
                    i.product = formatProduct(i.product, []);
                    items.push(i);
                })
                response.data.items = items;
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function setOrderShippingInfo(token, id, shippingType, branchId, addressId, shippingTime) {
    let endPoint = '';
    if (shippingType === 'take') {
        endPoint = `order/${id}/shipping?token=${token}&shipping=${shippingType}&branch=${branchId}`;
    } else {
        endPoint = `order/${id}/shipping?token=${token}&shipping=${shippingType}&address=${addressId}&shippingTime=${shippingTime}`;
    }
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data;
            } else {
                return 0;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function setOrderPromoCode(token, id, promoCode) {

    promoCode = encodeURIComponent(promoCode);

    let endPoint = `order/${id}/promo?token=${token}&promo=${promoCode}`;


    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1000, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1001, message: error.message};
        });
}

export async function setPaymentInfo(token, id, paymentType) {
    let endPoint = `order/${id}/payment?token=${token}&payment=${paymentType}`;

    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data;
            } else {
                return 0;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function payOrder(token, id, installment, method) {
    let endPoint = `order/${id}/pay?token=${token}&method=${method}&installment=${installment}`;

    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function checkPayment(token, id) {
    let endPoint = `order/${id}/checkPayment?token=${token}`;

    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function getOrders(token) {
    const endPoint = `orders?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getAddressList(token) {
    const endPoint = `address?token=${token}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getCityList() {
    const endPoint = `cities`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getDistrictList(cityId) {
    const endPoint = `districts?city=${cityId}`;
    return await Repository.get(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function addNewAddress(token, districtId, name, address, zip, isMain) {
    const endPoint = `address?token=${token}`;

    const formData = new FormData()
    formData.append('district', districtId);
    formData.append('name', name);
    formData.append('address', address);
    formData.append('zip', zip);
    formData.append('is_main', isMain ? 1 : 0);

    return await Repository.post(`${baseUrl}/${endPoint}`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return {code: 1000, message: response.statusText};
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {code: 1001, message: error.message};
        });
}


export async function deleteAddress(token, addressId) {
    const endPoint = `address/${addressId}/delete?token=${token}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}

export async function addToViewHistory(token, hash, productId) {
    const endPoint = `viewHistory?token=${token || ''}&hash=${hash}&product=${productId}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}


export async function addCallReqeust(token, hash, phone) {
    const endPoint = `callRequest?token=${token || ''}&hash=${hash}&phone=${phone}`;
    return await Repository.post(`${baseUrl}/${endPoint}`)
        .then(response => {
            if (response.data && response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return false;
        });
}
