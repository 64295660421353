<template>
    <div class="ps-menu--slidebar">
      <div class="text-right">
        <a
            href="javascript: void(0);"
            @click="handleCloseDrawer()"
        ><i class="icon-cross  text-warning" style="font-size: 30px; "></i
        ></a>
      </div>
        <div class="ps-menu__content">
            <module-main-menu :list="mainMenu" className="menu--mobile"/>
        </div>
        <div class="ps-menu__footer">
            <div class="ps-menu__item">
                <module-language-currency @close="handleCloseDrawer" />
            </div>
        </div>
    </div>
</template>

<script>
    import ModuleMainMenu from "./mobile/ModuleMainMenu";
    import ModuleLanguageCurrency from "./mobile/ModuleLanguageCurrency";
    import {getCategories} from "../../repositories/GeneralDataRepository";

    export default {
        components: {ModuleMainMenu, ModuleLanguageCurrency},
        data() {
            return {
                categories: []
            };
        },
        computed: {
            mainMenu() {
                return [
                    {
                        "text": this.$t('menu.products'),
                        "url": "/products",
                        "extraClass": "menu-item-has-children",
                        "subClass": "sub-menu",
                        "subMenu": this.categories
                    },
                    {
                        "text":  this.$t('menu.about'),
                        "url": "/page/about",
                        "extraClass": "nav-item"
                    },
                    {
                        "text":  this.$t('menu.blog'),
                        "url": "/blog",
                        "extraClass": "nav-item"
                    },
                    {
                        "text":  this.$t('menu.contact'),
                        "url": "/contact",
                        "extraClass": "nav-item"
                    }
                ]
            },
        },
      methods:{
        handleCloseDrawer() {
          this.$store.commit('app/setCurrentDrawerContent', null);
          this.$store.commit('app/setAppDrawer', false);
        }
      },
        async mounted() {
            await getCategories(this.languageCode).then(data => {

                const categories = [];

                data.forEach(cat => {
                    categories.push({
                        text: cat.name,
                        url: `/category/${cat.slug}`
                    })
                })

                this.categories = categories;
            });
        }
    };
</script>
