import cookie from 'js-cookie';
import {generateTempToken, getMe, login, register} from "../repositories/UserRepository";

const authStorage = {
    namespaced: true,
    state() {
        return {
            token: null,
            hash: null,
            user: null,
            loginError: null,
            registerError: null
        }
            ;
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload;
        },

        setHash(state, payload) {
            state.hash = payload;
        },

        setUser(state, payload) {
            state.user = payload;
        },
        setLoginError(state, payload) {
            state.loginError = payload;
        },
        setRegisterError(state, payload) {
            state.registerError = payload;
        },
    },
    actions: {
        setToken(context, payload) {
            context.commit('setToken', payload)
        },
        setHash(context, payload) {
            context.commit('setHash', payload)
        },
        setUser(context, payload) {
            context.commit('setUser', payload)
        },
        setLoginError(context, payload) {
            context.commit('setLoginError', payload)
        },
        setRegisterError(context, payload) {
            context.commit('setRegisterError', payload)
        },
        async loadUser(context, token) {
            await getMe(token).then(data => {

                if (data.code === 200) {
                    context.commit('setUser', data.user);
                } else if (data.code === 401) {
                    context.commit('setUser', null);
                    cookie.set('token', null);
                }

            })
        },
        async generateTempToken(context) {
            await generateTempToken().then(hash => {
                context.commit('setHash', hash);
                cookie.set('hash', hash);
            })
        },


        async login(context, {email, password}) {
            await login(email, password, context.state.hash).then(data => {

                if (data.code === 200) {
                    context.commit('setToken', data.token);
                    cookie.set('token', data.token);
                    context.dispatch('loadUser',data.token);
                } else if (data.code === 401) {
                    context.commit('setLoginError', "E-mail və ya şifrə yanlışdır");
                } else {
                    context.commit('setLoginError', data.message);
                }

            })
        },

        async logout(context) {
            cookie.set('token', '');
            await context.dispatch('loadUser');
        },

        async register(context, {name, surname, birthday, gender, phone, email, password, captcha}) {
            await register(name, surname, birthday, gender, phone, email, password, captcha, context.state.hash).then(data => {

                if (data.code === 200) {
                    cookie.set('token', data.token);
                    context.commit('setToken', data.token);
                    context.dispatch('loadUser', data.token);
                } else {
                    context.commit('setRegisterError', data.message + ': ' + data.error);
                }

            })
        },
    },
    getters: {}
}

export default authStorage;
