<template>
  <div style="padding-top: 100px;">
    <div class="ps-about" v-if="!isLoading">
      <div class="container">
        <breadcrumb :dataList="breadcrumb"/>
      </div>
      <div class="container">
        <div class="ps-about__content" v-if="page">
          <section class="ps-about__project">
            <div class="container">

              <div class="row justify-content-center">
                <div class="col-md-12">
                  <img :src="imageWidePath + page.banner" alt="alt" class="w-100">
                </div>
                <div class="col-md-10">
                  <h1 class="ps-blog__title my-4">{{ page.title }}</h1>

                  <div v-html="page.description"></div>
                </div>
                <div class="col-md-12">

                  <div class="row">

                  <div class=" col-md-4 mb-4" v-for="b in blogs.slice(0,5)" :key="b.id">
                    <BlogPost :blog="b"/>
                  </div>
                  </div>
                </div>
              </div>

            </div>
          </section>


        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        {{$t('general.loading')}}
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumb from "../layout/page/Breadcrumb";
import {getBlogPost, getBlogs} from "../../repositories/GeneralDataRepository";
import {mapState} from "vuex";
import {imageWidePath} from "@/repositories/Repository";
import BlogPost from "@/components/layout/BlogPost.vue";

export default {
  name: "BlogPostPage",
  components: {BlogPost, Breadcrumb},
  data() {

    return {
      isLoading: true,
      page: null,
      breadcrumb: [],
      blogs: [],
      imageWidePath: imageWidePath
    };

  },
  computed: {
    ...mapState({
      languageCode: state => state.app.languageCode
    })
  },
  watch: {
    '$route'() {
      this.loadData();
    }
  },
  methods: {
    async loadData() {

      this.isLoading = true;
      this.$store.commit('app/setLoading', true);
      await getBlogPost(this.$route.params.slug).then(data => {
        if (!data) {
          this.$router.push("/");
        } else {
          this.page = data;
          this.breadcrumb = [
            {
              url: '/',
              text: this.$t('menu.home')
            },
            {
              url: '/blog',
              extraClass: 'active',
              text: this.$t('menu.blog')
            },
            {
              url: '/blog/' + data.slug,
              extraClass: 'active',
              text: data.title
            }
          ];
          this.isLoading = false;
        }
      })

      this.$store.commit('app/setLoading', false);

    }
  },

  async mounted() {
    this.loadData();

    await getBlogs().then(data => {
      this.blogs = data;
    })
  }
}
</script>

<style scoped>

</style>
