<template>
  <div style="padding-top: 100px;">
    <div class="ps-account">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 ">
            <div class="card shadow">
              <div class="card-header p-0">

                <h2 class="p-4 m-0">
                  {{ $t('login.forget.question') }}
                </h2>
              </div>
              <div class="card-body p-5">

                <div class="form-group">
                  <label for="loginEmail" class="ps-form__label">
                    {{$t('login.email')}} *
                  </label>
                  <input
                      class="form-control p-3"
                      type="email"
                      id="loginEmail"
                      v-model="loginEmail"
                      required
                      @input="v$.loginEmail.$touch()"
                      @blur="v$.loginEmail.$touch()"
                  />
                  <div class="text-danger" v-for="e in loginEmailErrors" :key="e">{{ e }}</div>
                </div>

                <p v-if="forgetError != null" class="alert alert-danger">
                  {{ forgetError }}
                </p>
                <div class="my-3" v-if="!loading">
                  <button class="ps-btn ps-btn--warning mr-4" @click="handleForget()">
                    {{$t('login.forget.action')}}
                  </button>
                </div>
                <div class="my-3" v-else>
                  <div class="alert alert-info">
                    {{$t('login.forget.requesting')}}
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';
import {mapState} from "vuex";
import {forget} from "@/repositories/UserRepository";

export default {
  layout: 'default',
  validations: {
    loginEmail: {required, email},
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      loading: false,
      show1: false,
      loginEmail: null,
      forgetError: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    loginEmailErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "loginEmail") {
              if (error.$validator === 'email') {
                errors.push(this.$t('login.forget.wrongEmailFormat'));
              }
              if (error.$validator === 'required') {
                errors.push(this.$t('login.forget.emailRequired'));
              }
            }
          }
      )
      return errors;
    },
  },
  methods: {
    async handleForget() {
      this.v$.loginEmail.$touch();
      console.log(this.loginPassword);
      if (!this.v$.$invalid && !this.loading) {

        this.loading  = true;
        await forget(this.loginEmail).then(data => {
          this.loading  = false;
          if (data.code === 200) {
            this.forgetError = null;
            this.loginEmail = null;
            alert(this.$t('login.forget.emailSent'));

            this.$router.push('/login');

          } else if (data.code === 400) {
            this.forgetError = this.$t('login.forget.emailRequired');
          } else {
            this.forgetError = data.message;
          }

        })


      }
    }
  },
  watch: {
    user(newValue) {
      if (newValue !== null) {

        let l = localStorage.getItem("goBackAfterLogin");
        localStorage.removeItem("goBackAfterLogin")
        if (!l) {
          l = '/';
        }
        window.location.href = l;
      }
    }
  }
}
;
</script>
