<template>
    <div>

      <div class="card mb-4" v-if="user">
        <div class="card-body">
          <div class="ps-contact__info">
            <div class="row">
              <div class="col-md-5">
                <h2 class="ps-contact__title">{{user.name}} {{user.surname}}</h2>
              </div>
              <div class="col-md-4">
                <div class="ps-contact__work">
                  <strong>{{$t('profile.email')}}:</strong> {{user.email}}<br>
                  <strong>{{$t('profile.phone')}}:</strong> {{user.phone}}
                </div>
              </div>
              <div class="col-md-3 text-right">
                <div class="ps-contact__email border-0">
                  <a href="#" @click.prevent="logoutUser"
                     class="text-center">
                    {{$t('profile.logout.btn')}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <section class="ps-section--category ps-category--image">
            <div class="ps-section__content">
                <div class="row">
                    <div class="col-6 col-sm-4" v-for="(item, index) in categoryImage"
                         :key="index">
                        <div class="ps-category__thumbnail">
                            <router-link class="ps-category__image" :to="item.link"><img
                                    :src="item.image" alt></router-link>
                            <div class="ps-category__content">
                                <router-link class="ps-category__name" :to="item.link">
                                    {{ item.name }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>


    import {mapState} from "vuex";

    export default {
        name: "Profile",
        computed: {

          ...mapState(
              {user: state => state.auth.user}
          ),
            categoryImage() {
                return [
                    /*{
                        "name": "Hesab məlumatları",
                        "image": "/img/promotion/facemask-cat.png",
                        "link": "/profile/details"
                    },*/
                    {
                        "name":  this.$t('profile.password.title') ,
                        "image": "/img/promotion/facemask-cat.png",
                        "link": "/profile/password"
                    },
                    {
                        "name": this.$t('profile.orders.title') ,
                        "image": "/img/promotion/uniforms-cat.png",
                        "link": "/profile/orders"
                    },
                    {
                        "name": this.$t('profile.favorites.title'),
                        "image": "/img/promotion/dental-cat.png",
                        "link": "/profile/favorites"
                    },
                    {
                        "name": this.$t('profile.viewHistory.title'),
                        "image": "/img/promotion/dental-cat.png",
                        "link": "/profile/viewHistory"
                    },
                    {
                        "name": this.$t('profile.addresses.title'),
                        "image": "/img/promotion/dental-cat.png",
                        "link": "/profile/addresses"
                    },
                ];
            }
        },
      methods: {
        logoutUser() {
          if(confirm(this.$t('profile.logout.confirm'))) {
            this.$store.dispatch('auth/logout');
          }
        }
      },
    }
</script>

<style scoped>

</style>
