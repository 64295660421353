<template>
  <section class="ps-section--featured ps-category--grid ps-category--detail border-0">
    <div class="container">
      <h3 class="ps-section__title">{{ title }}</h3>
      <div class="ps-section__content">
        <div class="row justify-content-center" v-if="loading">
          <div
              class="col-12 text-center p-5"
          >
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        </div>
        <div class="row m-0" v-else>
          <div
              class="col-6 col-sm-4 col-lg-3 p-0"
              v-for="(item, index) in products"
              :key="index"
          >
            <product-standard :product="item"/>
          </div>
        </div>

        <div class="text-center mt-4" v-if="buttonVisible">
          <router-link class="button-special" to="/products">
            {{ $t('general.allProducts') }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductStandard from "../product/ProductStandard";
import {mapState} from 'vuex';
import {getAllProducts, getTagProducts} from "@/repositories/ProductRepository";
import {getViewHistory} from "@/repositories/UserRepository";

export default {
  props: {
    tag: {
      type: String,
      default: () => {
      }
    },
    title: {
      type: String,
      default: () => {
      }
    },
    count: {
      type: Number,
      default: () => {
        return 10;
      }
    },
    buttonVisible: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  components: {ProductStandard},
  data() {
    return {
      dataList: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.app.loading,
      favorites: state => state.wishlist.items,
      token: state => state.auth.token,
      hash: state => state.auth.hash,
      settings: state => state.app.settings,
    }),
    products() {
      let products = [];
      this.dataList.forEach((e) => {
        e.isFavorite = this.favorites.indexOf(e.id + "") > -1;
        products.push(e);
      });
      if (this.count < products.length) {
        return products.slice(0, this.count);
      }
      return products;
    }

  },

  watch: {
    '$i18n.locale'() {
      this.loadList();
    }
  },

  methods: {

    loadList() {


      if (this.tag === 'viewed') {
        getViewHistory(this.token, this.hash, this.count).then(data => {
          this.dataList = data.map((e) => {
            return e.product;
          })
        });
      } else if (this.tag === 'top') {

        getTagProducts(this.$i18n.locale, this.tag).then(data => {
          this.dataList = data;
        });
      } else if (this.tag === 'new') {

        getAllProducts(this.$i18n.locale, '', 0, 0, 1, this.count, 'latest').then(data => {
          this.dataList = data.products;
        })
      } else if (this.tag === 'all') {

        getAllProducts(this.$i18n.locale, '', 0, 0, 1, this.count, 'recommended').then(data => {
          this.dataList = data.products;
        })
      } else {

        getTagProducts(this.$i18n.locale, this.tag).then(data => {
          this.dataList = data;
        });
      }

    }
  },
  mounted() {

    this.loadList();
  }
};
</script>

<style lang="scss" scoped>
.button-special {
  background: #0F3484;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  border-radius: 25px;
  box-sizing: border-box;
  display: inline-block;
  text-transform: uppercase;
}
</style>
