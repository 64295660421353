<template>

  <div style="padding-top: 100px;">
    <div class="ps-blog ps-blog--masonry">
      <div class="container">
        <breadcrumb :dataList="breadcrumb"/>
      </div>
      <div class="container mt-5">
        <h1 class="ps-blog__title text-left">
          {{$t('blog.title')}}
        </h1>
        <div class="ps-blog__content">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4" v-for="b in currentPageBlogs" :key="b.id">

              <BlogPost :blog="b"/>
            </div>
          </div>


          <div class="my-4">

            <div class="ps-pagination">
              <ul class="pagination">
                <li class="page-item" :class="{disabled: page === 1}" @click="page > 1 ? page-- : null">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">{{ page }}</a>
                </li>
                <li class="page-item" :class="{disabled: page === pageCount}" @click="page < pageCount ? page++ : null">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {baseUrl, imagePath, imageWidePath} from "@/repositories/Repository";
import {getBlogs} from "@/repositories/GeneralDataRepository";
import BlogPost from "@/components/layout/BlogPost.vue";
import Breadcrumb from "@/components/layout/page/Breadcrumb.vue";

export default {
  name: "BlogListPage",
  components: {Breadcrumb, BlogPost},
  data() {
    return {
      blogs: [],

      page: 1,
      pageSize: 12,

      baseDomain: baseUrl,
      imagePath: imagePath,
      imageWidePath: imageWidePath,
      breadcrumb:[
        {
          url: '/',
          text: this.$t('menu.home')
        },
        {
          url: '/blog',
          extraClass: 'active',
          text: this.$t('menu.blog')
        }
      ]
    }
  },

  computed: {
     currentPageBlogs() {
       return this.blogs.slice((this.page-1)*this.pageSize, this.page*this.pageSize);
     },
    pageCount() {
      return Math.ceil(this.blogs.length / this.pageSize);
    }
  },
  methods: {
    async getBlogs() {

      await getBlogs().then(data => {
        this.blogs = data;
      })

    },

  },
  created() {
    this.getBlogs();
  },
}
</script>

<style scoped>

.ps-blog__title {
  color: #103178;
  text-align: center;
}
</style>
