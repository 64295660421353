<template>
    <ul class="ps-language-currency" v-if="languages.length>1">
        <li class="menu-item-has-children text-center" v-for="l in languages" :key="l.code">
            <a href="#" @click.prevent="handleSelectLanguage(l)">{{l.name}}</a>
        </li>
    </ul>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        components: {},
        data() {
            return {
                isOpen: false
            }
        },
        computed: {
            ...mapState(
                {
                    languages: state => state.app.languages,
                    language: state => state.app.language,
                }
            ),
            data() {
                console.log(this.language);
                console.log(this.languages);
                return {
                    text: this.language,
                    subClass: "sub-menu",
                    url: '#',
                    subMenu: this.languages
                };
            }
        }
        ,
        methods: {
            openSubItem() {
                this.isOpen = !this.isOpen;
            },

          handleSelectLanguage(value) {
            this.$store.dispatch('app/setLanguage', value);
            this.$i18n.locale = value.code;
            this.$emit('close');
          }
        }
    }
</script>
