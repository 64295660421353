<template>
  <div>
    <div class="ps-shopping">
      <div class="container" v-if="orderDetails!== null">
        <div class="row">
          <div class="col-md-12 mb-3"  v-if="orderDetails.orderStatus==='waiting_payment'">
            <div class="alert alert-danger">
              {{$t('order.warnings.needToPay')}}
              <br>
              <a href="#" @click.prevent="openPaymentMethods()">
                <u>
                {{$t('order.payNow')}}
              </u>
              </a>
            </div>
          </div>
          <div class="col-md-12 mb-3"  v-if="orderDetails.orderStatus==='new'">
            <div class="alert alert-danger">
              {{$t('order.warnings.chooseDelivery')}}
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="ps-shopping__title">
              {{ $t('order.number',{id: orderDetails.id}) }}
            </h3>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <div>
            <div class="alert alert-warning" v-if="orderDetails.orderStatus==='new'">
              {{$t('order.status.new')}}
            </div>
            <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='confirmed'">
              {{$t('order.status.confirmed')}}
            </div>
            <div class="alert alert-danger"
                 v-else-if="orderDetails.orderStatus==='waiting_payment'">
              {{$t('order.status.waiting_payment')}}
            </div>
            <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='paid'">
              {{$t('order.status.paid')}}
            </div>
            <div class="alert alert-primary" v-else-if="orderDetails.orderStatus==='preparing'">
              {{$t('order.status.prepearing')}}
            </div>
            <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='delivering'">
              {{$t('order.status.delivering')}}
            </div>
            <div class="alert alert-success" v-else-if="orderDetails.orderStatus==='finished'">
              {{$t('order.status.finished')}}
            </div>
            <div class="alert alert-danger" v-else-if="orderDetails.orderStatus==='cancelled'">
              {{$t('order.status.canceled')}}
            </div>
            <div class="alert alert-success" v-else>
              {{ orderDetails.orderStatus }}
            </div>
            </div>
          </div>
        </div>

        <div class="ps-shopping__content">
          <div class="row">

            <div class="col-lg-4 col-md-6">
              <div class="card mb-4">

                <div class="card-header">{{$t('order.delivery.title')}}</div>
                <div class="card-body" v-if="editShippingType">
                  <form @submit.prevent="updateShippingInfo()">
                    <div class="mb-3">
                      <label>
                        {{$t('order.delivery.method')}}
                         </label>
                      <select class="form-control" v-model="orderDetails.shippingType">
                        <option value="take">{{$t('order.delivery.takeMyself')}}</option>
                        <option value="delivery">{{$t('order.delivery.delivery')}}</option>
                      </select>
                    </div>

                    <div class="mb-3" v-if="orderDetails.shippingType==='take'">

                      <div v-if="branches.length>1">
                        <label>Filialı seçin</label>
                        <select class="form-control" v-model="deliveryBranch">
                          <option v-for="b in branches" :value="b.id" :key="b.id">
                            {{ b.name }}
                          </option>
                        </select>
                      </div>
                      <div v-if="branches.length<1">
                        <div class="alert alert-info">
                          Filial yoxdur
                        </div>
                      </div>
                      <div v-else>
                        <input type="hidden" v-model="deliveryBranch">
                      </div>

                      <div class="my-3" v-if="orderDetails.branch !== null">
                        <button type="submit" class="ps-btn ps-btn--warning">
                          {{$t('order.delivery.save')}}
                        </button>
                        <div class="text-center mt-2">
                          <a class="ps-shopping__link"
                             @click.prevent="editShippingType=false">
                            {{$t('order.delivery.cancel')}}
                          </a>
                        </div>
                      </div>

                    </div>
                    <div class="mb-3" v-else-if="orderDetails.shippingType==='delivery'">
                      <label>{{$t('order.delivery.address')}}</label>
                      <select class="form-control" v-model="deliveryAddress">
                        <option v-for="a in addresses" :value="a.id" :key="a.id">
                          {{ a.name }}
                        </option>
                      </select>


                      <a class="ps-shopping__link" @click.prevent="addAddressOpen=!addAddressOpen">
                        {{$t('profile.addresses.add')}}
                      </a>

                      <div class="my-3" v-if="addAddressOpen">
                        <hr>
                        <add-addess-form @addressCreated="addressCreated"></add-addess-form>
                      </div>

                      <div class="my-3" v-if="orderDetails.address !== null">
                        <button type="submit" class="ps-btn ps-btn--warning">
                          {{$t('order.delivery.save')}}
                        </button>
                        <div class="text-center mt-2">
                          <a class="ps-shopping__link"
                             @click.prevent="editShippingType=false">
                            {{$t('order.delivery.cancel')}}
                          </a>
                        </div>
                      </div>

                    </div>

                    <div v-else>
                      <div class="alert alert-danger">
                        {{$t('order.delivery.choose')}}
                      </div>
                    </div>
                  </form>

                </div>
                <div class="card-body" v-else>
                  <div class="mb-3">
                    <strong>{{$t('order.delivery.method')}}: </strong>
                    <span v-if="orderDetails.shippingType==='take'">{{$t('order.delivery.takeMyself')}}</span>
                    <span v-else-if="orderDetails.shippingType==='delivery'">{{$t('order.delivery.delivery')}}</span>
                    <span v-else>Zəhmət olmasa seçin</span>
                  </div>

                  <div class="mb-3" v-if="orderDetails.shippingType==='take'">
                    <div>
                      <strong>{{ orderDetails.branch.name }}</strong> <br>
                      <span>{{ orderDetails.branch.address }}</span> <br>
                      <span v-html="orderDetails.branch.schedule"></span>
                    </div>
                  </div>
                  <div class="mb-3" v-else-if="orderDetails.shippingType==='delivery'">
                    <strong>{{ orderDetails.address.name }}</strong> <br>
                    <strong>{{ orderDetails.address.district.city.name }},
                      {{ orderDetails.address.district.name }}</strong> <br>
                    <span>{{ orderDetails.address.address }}</span> <br>

                  </div>

                  <div v-else>
                    <div class="alert alert-info">
                      {{$t('order.delivery.choose')}}
                    </div>
                  </div>

                  <div class="my-3"
                       v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                    <a class="ps-shopping__link"
                       @click.prevent="editShippingType=true">
                      {{$t('order.delivery.edit')}}
                    </a>
                  </div>

                </div>


              </div>
            </div>
            <div class="col-lg-4 col-md-6">

              <div class="card mb-3">

                <div class="card-header">
                  {{$t('order.promo.title')}}
                </div>
                <div class="card-body" v-if="editingPromoCode || !orderDetails.promoCode ">
                  <form @submit.prevent="setPromoCode">
                    <div class="mb-3">
                      <label>{{$t('order.promo.code')}}: </label>
                      <input class="form-control" required v-model="promoCode">
                    </div>


                    <div class="my-3">
                      <button type="submit" class="ps-btn ps-btn--warning">
                        {{$t('order.promo.apply')}}
                      </button>

                      <div class="text-center mt-2" v-if="orderDetails.promoCode">
                        <a class="ps-shopping__link" href="#"
                           @click.prevent="editingPromoCode=false">
                          {{$t('order.promo.cancel')}}
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-body" v-else>
                  <div class="mb-3">
                    <strong>{{$t('order.promo.code')}}: </strong>
                    <span class="badge badge-success p-2 px-3">{{ orderDetails.promoCode.code }}</span>
                    <span class="badge badge-primary ml-2 p-2 px-3">{{ orderDetails.promoDiscountPercent }}%</span>
                  </div>

                  <div class="my-3"
                       v-if="['new','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                    <a class="ps-shopping__link" href="#"
                       @click.prevent="editingPromoCode=true">
                      {{$t('order.promo.change')}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4" v-if="1<0">

              <div class="card mb-3">

                <div class="card-header">Ödəniş</div>
                <div class="card-body" v-if="editPaymentType">
                  <form @submit.prevent="updatePaymentInfo()">
                    <div class="mb-3">
                      <label>Ödəniş metodu: </label>
                      <select class="form-control" v-model="orderDetails.paymentType">
                        <option value="cash">Çatdırılma zamanı nağd</option>
                        <option value="card">Online</option>
                      </select>
                    </div>


                    <div class="my-3" v-if="orderDetails.paymentType !== 'none'">
                      <button type="submit" class="ps-btn ps-btn--warning">
                        Ödəmə üsulunu təsdiqlə
                      </button>

                      <div class="text-center mt-2">
                        <a class="ps-shopping__link" href="#"
                           @click.prevent="editPaymentType=false">
                          ləğv et
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-body" v-else>
                  <div class="mb-3">
                    <strong>Ödəniş metodu: </strong>
                    <span v-if="orderDetails.paymentType==='cash'">Çatdırılma zamanı nağd</span>
                    <span v-else-if="orderDetails.paymentType==='card'">Online</span>
                    <span v-else>Zəhmət olmasa seçin</span>
                  </div>

                  <div class="my-3"
                       v-if="['new','confirmed','waiting_payment'].indexOf(orderDetails.orderStatus)>-1">
                    <a class="ps-shopping__link" href="#"
                       @click.prevent="editPaymentType=true">
                      Ödəniş ayarlarını dəyiş
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">


              <div class="ps-shopping__box">
                <div class="ps-shopping__row">
                  <div class="ps-shopping__label">
                    {{$t('order.summary.total')}}
                  </div>
                  <div class="ps-shopping__price">{{ orderDetails.totalPrice }} AZN</div>
                </div>
                <div class="ps-shopping__row" v-if="orderDetails.personalDiscountPercent>0">
                  <div class="ps-shopping__label">{{$t('order.summary.personalDiscount')}}</div>
                  <div class="ps-shopping__price">{{ orderDetails.personalDiscountAmount }} AZN
                    ({{ orderDetails.personalDiscountPercent }} %)
                  </div>
                </div>

                <div class="ps-shopping__row" v-if="orderDetails.promoCode">
                  <div class="ps-shopping__label">{{$t('order.summary.total')}}</div>
                  <div class="ps-shopping__price">{{ orderDetails.discount }} AZN
                    ({{ orderDetails.promoDiscountPercent }} %)
                  </div>
                </div>
                <div class="ps-shopping__row">
                  <div class="ps-shopping__label">{{$t('order.summary.delivery')}}</div>
                  <div class="ps-shopping__price">{{ orderDetails.deliveryPrice }} AZN</div>
                </div>
                <div class="ps-shopping__row">
                  <div class="ps-shopping__label">{{$t('order.summary.toPay')}}</div>
                  <div class="ps-shopping__price">{{ orderDetails.price }} AZN</div>
                </div>
                <div class="ps-shopping__checkout">
                  <a href="#" class="ps-btn ps-btn--warning" @click.prevent="openPaymentMethods()"
                     v-if=" orderDetails.orderStatus === 'waiting_payment' ||  orderDetails.orderStatus === 'new'">
                    {{$t('order.payNow')}}
                  </a>
                  <router-link class="ps-shopping__link" to="/products">
                    {{$t('cart.continueShopping')}}
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-12">


              <div class="card mb-4">

                <div class="card-header">
                  {{$t('order.products.title')}}
                </div>
                <div class="card-body">

                  <table class="table ps-table ps-table--product">
                    <thead>
                    <tr>
                      <th class="ps-product__thumbnail"></th>
                      <th class="ps-product__name"> {{$t('order.products.product')}}</th>
                      <th class="ps-product__meta"> {{$t('order.products.price')}}</th>
                      <th class="ps-product__quantity"> {{$t('order.products.quantity')}}</th>
                      <th class="ps-product__subtotal"> {{$t('order.products.total')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(orderItem, index) in orderItems" :key="index">
                      <td class="ps-product__thumbnail">
                        <router-link class="ps-product__image"
                                     :to="`/product/${orderItem.product.slug}`"
                                     v-if="orderItem.product.thumbnail">
                          <figure><img :src="baseDomain + orderItem.product.thumbnail.url"
                                       alt>
                          </figure>
                        </router-link>
                      </td>
                      <td class="ps-product__name">
                        <router-link :to="`/product/${orderItem.product.slug}`">
                          {{ orderItem.product.name }}
                        </router-link>
                        <br>
                        <a class="mx-3 btn btn-sm btn-warning"
                           @click="openRateModal(orderItem.product)"
                           v-if="orderDetails.orderStatus==='finished'">
                          dəyərləndir
                        </a>

                      </td>
                      <td class="ps-product__meta">
                                            <span
                                                :class="[ 'ps-product__price',orderItem.product.sale_price ? 'sale' : '']">
                                                {{
                                                orderItem.product.sale_price ? orderItem.product.sale_price : orderItem.product.price
                                              }} AZN
                                            </span>
                        <span class="ps-product__del" v-if="orderItem.product.sale_price">
                                            {{ orderItem.product.price }} AZN
                                        </span>
                      </td>
                      <td class="ps-product__meta text-center">
                        <span>{{ orderItem.count }} </span>
                      </td>
                      <td class="ps-product__meta">
                        <span>{{ orderItem.price }} AZN </span>
                      </td>
                    </tr>
                    <tr v-if="!orderItems || !orderItems.length">
                      <td colspan="6"> {{$t('general.loading')}}</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="alert alert-info">
          {{$t('general.loading')}}
        </div>
      </div>
    </div>
  </div>

  <modal-slot :isVisible="dialogPaymentMethod" title="" modalSize="md"
              @modalClose="dialogPaymentMethod= false">

    <div class="p-3">
      <h4>{{ $t('order.payment.title') }}</h4>
      <p>{{ $t('order.payment.description') }}</p>

      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="paymentMethod" id="paymentMethodAzericard" v-model="paymentMethod" value="azericard">
        <label class="form-check-label" for="paymentMethodAzericard">
          {{ $t('order.payment.tamkart.title') }}
        </label>
      </div>

      <div class="ml-5" v-if="paymentMethod === 'azericard'">
        <div class="alert alert-info small">
          {{ $t('order.payment.tamkart.installment') }}
        </div>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="paymentMethod" id="paymentMethodKapital" v-model="paymentMethod" value="kapital">
        <label class="form-check-label" for="paymentMethodKapital">
          {{ $t('order.payment.kapital.title') }}
        </label>
      </div>
      <div class="ml-5" v-if="paymentMethod === 'kapital'">
        <div class="form-check mb-3">
          <input class="form-check-input" type="radio" name="paymentInstallment" id="paymentInstallment1" v-model="installment" :value="1">
          <label class="form-check-label" for="paymentInstallment1">
            {{ $t('order.payment.kapital.installment.none') }}
          </label>
        </div>
        <div class="form-check mb-3">
          <input class="form-check-input" type="radio" name="paymentInstallment" id="paymentInstallment2" v-model="installment" :value="2">
          <label class="form-check-label" for="paymentInstallment2">
            {{ $t('order.payment.kapital.installment.month2') }}
          </label>
        </div>
        <div class="form-check mb-3">
          <input class="form-check-input" type="radio" name="paymentInstallment" id="paymentInstallment3" v-model="installment" :value="3">
          <label class="form-check-label" for="paymentInstallment3">
            {{ $t('order.payment.kapital.installment.month3') }}
          </label>
        </div>
      </div>


      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="paymentMethod" id="paymentMethodOther" v-model="paymentMethod" value="other">
        <label class="form-check-label" for="paymentMethodOther">
          {{ $t('order.payment.other') }}
        </label>
      </div>

      <button class="ps-btn ps-btn--warning mb-2" @click="payOrder()">
        {{ $t('order.payment.pay') }}
      </button>

      <div class="text-center mt-3">
      <a href="#" class="text-primary" @click="dialogPaymentMethod= false">
        {{ $t('order.payment.cancel') }}
      </a>
      </div>

    </div>

  </modal-slot>
</template>

<script>
import {mapState} from 'vuex';
import {baseUrl} from '../../repositories/Repository';
import {
  getAddressList,
  getOrder,
  payOrder, setOrderPromoCode,
  setOrderShippingInfo,
  setPaymentInfo
} from "../../repositories/UserRepository";
import {getBranches} from "../../repositories/GeneralDataRepository";

import AddAddessForm from "../layout/AddAddessForm";
import ModalSlot from "@/components/layout/ModalSlot.vue";

export default {
  layout: 'default',
  components: {ModalSlot, AddAddessForm},
  data() {
    return {

      baseDomain: baseUrl,
      orderDetails: null,
      orderItems: [],
      editShippingType: false,
      branches: [],
      addresses: [],
      editPaymentType: false,
      addAddressOpen: false,

      editingPromoCode: false,
      promoCode: '',

      dialogPaymentMethod: false,
      installment: 1,
      paymentMethod: 'azericard',
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
    orderId() {
      return this.$route.params.id;
    },
    breadcrumb() {
      return [
        {
          url: '/',
          text:  this.$t('menu.home')
        },
        {
          url: '/profile/orders',
          text: this.$t('profile.orders.title')
        },
        {
          url: '/profile/order/' + this.orderDetails.id,
          extraClass: 'active',
          text: this.$t('order.number', {id: this.orderDetails.id})
        }
      ];
    },
    deliveryBranch: {
      get() {
        if (this.orderDetails.branch == null) {
          return null
        } else {
          return this.orderDetails.branch.id;
        }
      },
      set(id) {
        this.orderDetails.branch = {id: id};
      }
    },
    deliveryAddress: {
      get() {
        if (this.orderDetails.address == null) {
          return null
        } else {
          return this.orderDetails.address.id;
        }
      },
      set(id) {
        this.orderDetails.address = {id: id};
      }
    },
  },
  watch: {
    orderId() {
      this.loadOrder();
    },
    'orderDetails.shippingType': function (val) {
      if (val === 'take') {
          this.updateShippingInfo();
      }
    },

    paymentMethod() {
       this.installment = 1;
    }
  },
  async created() {

    this.loadOrder();

    this.loadAddressList();

    this.$store.commit('app/setLoading', false);
  },
  methods: {
    loadOrder() {
      getOrder(this.token, this.orderId).then((data) => {
        if (data !== null) {
          this.orderDetails = data.order;
          this.orderItems = data.items;
          if (this.orderDetails.shippingType === 'none') {
            this.editShippingType = true;
          }
          if (this.orderDetails.paymentType === 'none') {
            this.editPaymentType = true;
          }
          getBranches('az').then((branches) => {
            this.branches = branches;
            if (branches.length === 1) {
              this.deliveryBranch = branches[0].id;
              this.orderDetails.branch = branches[0];
            }
          })

        } else {
          this.orderDetails = null;
          this.orderItems = [];
        }
      })
    },
    handleCoupon() {
      console.log('couponCode', this.couponCode);
    },
    updateShippingInfo() {

      setOrderShippingInfo(
          this.token, this.orderDetails.id, this.orderDetails.shippingType,
          this.deliveryBranch, this.deliveryAddress, ''
      ).then(() => {
        this.editShippingType = false;
        this.loadOrder();
      });

    },
    setPromoCode() {

      setOrderPromoCode(
          this.token, this.orderDetails.id, this.promoCode
      ).then(({code}) => {

        if (code === 200) {

          this.promoCode = '';
          this.editingPromoCode = false;
          this.loadOrder();
        } else if (code === 404) {

          const message = {
            icon: 'fa fa-info-circle',
            message: 'Endirim kodu tapılmadı!'
          };
          this.$store.commit('app/setMessageNotify', message);
          this.$store.commit('app/setDialogNotify', true);



        } else if (code === 403) {
          const message = {
            icon: 'fa fa-info-circle',
            message: 'Endirim kodu aktiv deyil'
          };
          this.$store.commit('app/setMessageNotify', message);
          this.$store.commit('app/setDialogNotify', true);

        } else {
          const message = {
            icon: 'fa fa-info-circle',
            message: 'Endirim kodu tətbiq edilə bilmədi'
          };
          this.$store.commit('app/setMessageNotify', message);
          this.$store.commit('app/setDialogNotify', true);

        }
      });

    },
    updatePaymentInfo() {

      setPaymentInfo(
          this.token, this.orderDetails.id, this.orderDetails.paymentType
      ).then(() => {
        this.editPaymentType = false;
        this.loadOrder();
      });

    },


    openPaymentMethods() {
      if(this.orderDetails.orderStatus !=='waiting_payment'){

        const message = {
          icon: 'fa fa-info-circle',
          message: 'Ödənişə davam etmək üçün çatdırılma növünü seçin!'
        };
        this.$store.commit('app/setMessageNotify', message);
        this.$store.commit('app/setDialogNotify', true);

        return;
      }
      this.dialogPaymentMethod = true;
    },
    payOrder() {

      payOrder(
          this.token, this.orderDetails.id, this.installment, this.paymentMethod
      ).then((data) => {
        if (data === null) {
          alert('Əməliyyatı icra etmək mümkün olmadı, zəhmət olmasa bir az sonra təkrar cəhd edin')
        } else {

          if (data.method === 'azericard') {
            let url = data.url;
            let post = data.data;
            let minfo = {
              "browserScreenHeight": window.screen.height,
              "browserScreenWidth": window.screen.width,
              "browserTZ": new Date().getTimezoneOffset()
            };
            post['M_INFO'] = btoa(JSON.stringify(minfo));
            let form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', url);
            //post data
            for (let key in post) {
              if (Object.prototype.hasOwnProperty.call(post, key)) {
                let hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', post[key]);
                form.appendChild(hiddenField);
              }
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);

          } else {
            window.location.href = data.url;
          }
        }
      });

    },

    openRateModal(product) {
      this.$store.commit('cart/setDialogRating', true);
      this.$store.commit('cart/setProductOverview', product);
    },
    loadAddressList() {
      getAddressList(this.token).then((addresses) => {
        this.addresses = addresses;
      })
    },
    addressCreated(addressId) {
      this.loadAddressList();
      this.deliveryAddress = addressId;
      this.addAddressOpen = false;
    }
  }
}
</script>
